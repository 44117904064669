import React, { lazy, Suspense } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const BeautyGuideProductList = lazy(() => import('./scenes/ePBG/BeautyGuideProductList'));
const AdminTool = lazy(() => import('./AdminTool'));

const App = () => (
  <Router>
    <Suspense fallback={<></>}>
      <Switch>
        <Route path="/epbg/:guideId">
          <BeautyGuideProductList />
        </Route>
        <Route path="/">
          <AdminTool />
        </Route>
      </Switch>
    </Suspense>
  </Router>
);

export default App;
